import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Footer from "./Components/Footer";
import PageHeader from "./Components/PageHeader";
import "./Welcome.css";
import useGetAccountStatus from "./hooks/useGetAccountStatus";

export default function Welcome() {
  const uuid = sessionStorage.getItem("UUID");
  const { mutate: accountStatus } = useGetAccountStatus();

  const navigate = useNavigate();

  const englishText = [
    "1. Change your password after login.",
    "2. If the game is canceled or tied, all the deals of the match will be considered canceled and the Coins will be more or less according to the session that has been completed.",
    "3. During the match Deal only by seeing and understanding. Any transaction entered into will not be deleted or replaced. You will be responsible for all transactions.",
    "4. Match or Session Quote Wrong Any matches or sessions that have been traded on the go will be automatically deleted.",
    "5. Coins will be more or less according to the session which will be completed in the match and all the sessions will be canceled according to the session which is not completed.",
    "6. Match I Over Come having But advance session fancy will get canceled.",
    "7. Overs less in the match having But if the same team comes to play again, the player's runs and partnership in that running will be declared. If over less If the other team comes to play, then the player runs in the running, the partnership will be canceled.",
    "8. If the batsman is injured, he scores 34 runs as a result of which 34 runs are awarded.",
    "9. Innings in test On being declared, the over or ball count of the other team will be done to declare the session which is in running.",
    "10. The decision of the company to declare and cancel the session will be final. In such a situation, any kind of debate will not be valid.",
    "11. It is an offense for anyone under the age of 18 to open an account or gamble on JMD20.com. Play at your own risk.",
    "12. Four, sixes, wide, wicket, extra run, total run, highest over and top batsman, maiden over, caught-out, no-ball, run-out, fifty and century are valid only if the match has been completed. In case due to rain over has been reduced all other fancy will be deleted.",
    "Note: Transactions made will be valid only in case of any failure or closure of the server or website. In such a case, any dispute will not be valid.",
  ];

  const hindiText = [
    "1. लॉगिन के बाद अपना पासवर्ड बदलें।",
    "2. यदि खेल रद्द या टाई हो जाता है, तो मैच की सभी डील रद्द मानी जाएंगी और सिक्कों की मात्रा पूरी हुई सत्र के अनुसार कम या ज्यादा होगी।",
    "3. मैच के दौरान केवल देखकर और समझकर डील करें। की गई किसी भी लेन-देन को हटाया या बदला नहीं जाएगा। आप सभी लेन-देन के लिए जिम्मेदार होंगे।",
    "4. मैच या सत्र का गलत कोटेशन किसी भी मैच या सत्र को, जो चल रहा हो, स्वतः ही हटा दिया जाएगा।",
    "5. सिक्कों की मात्रा पूरी हुई सत्र के अनुसार कम या ज्यादा होगी और सभी सत्र रद्द किए जाएंगे जो पूरे नहीं हुए।",
    "6. मैच में ओवर समाप्त हो गया, लेकिन अग्रिम सत्र फैन्सी रद्द हो जाएगा।",
    "7. मैच में कम ओवर हुए, लेकिन यदि वही टीम फिर से खेलने आती है, तो उस रनिंग में खिलाड़ी की रन और साझेदारी की घोषणा की जाएगी। यदि दूसरी टीम आती है, तो खिलाड़ी की रन और साझेदारी रद्द हो जाएगी।",
    "8. यदि बल्लेबाज घायल हो जाता है, तो उसे 34 रन दिए जाएंगे।",
    "9. टेस्ट में पारी घोषित होने पर, चल रहे सत्र की घोषणा के लिए दूसरी टीम की ओवर या गेंद की गिनती की जाएगी।",
    "10. कंपनी द्वारा सत्र घोषित और रद्द करने का निर्णय अंतिम होगा। ऐसी स्थिति में किसी भी प्रकार की बहस मान्य नहीं होगी।",
    "11. 18 वर्ष से कम आयु के किसी भी व्यक्ति के लिए JMD20.com पर खाता खोलना या सट्टा खेलना अपराध है। अपने जोखिम पर खेलें।",
    "12. चार, छक्का, वाइड, विकेट, अतिरिक्त रन, कुल रन, सर्वोच्च ओवर और टॉप बल्लेबाज, मेडेन ओवर, कैच आउट, नो-बॉल, रन आउट, पचास और शतक तभी मान्य होंगे जब मैच पूरा हो जाएगा। बारिश के कारण ओवर घटने की स्थिति में सभी अन्य फैन्सी हटाई जाएगी।",
    "नोट: लेन-देन तभी मान्य होंगे जब सर्वर या वेबसाइट बंद या विफल हो। ऐसी स्थिति में किसी भी प्रकार का विवाद मान्य नहीं होगा।",
  ];

  // State to track the current language
  const [isEnglish, setIsEnglish] = useState(true);

  // Function to switch between English and Hindi
  const handleLanguageSwitch = (language) => {
    setIsEnglish(language === "english");
  };

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: "user",
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        console.log(error);
        // toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <PageHeader />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "100px",
        }}
      >
        <Link to="/MainMenu">
          <button
            className="mt-4"
            style={{
              backgroundColor: "#0092ad",
              color: "white",
              padding: "10px",
              borderRadius: "5px",
              border: "none",
            }}
          >
            Continue
          </button>
        </Link>
      </div>

      <div
        className="Card pt-4 w-100"
        style={{ flexGrow: 1, padding: "20px", marginBottom: "48px" }}
      >
        <div className="card text-left">
          <h1 id="Termandcondition">Terms & Conditions</h1>
          <div className="pl-4 d-flex" style={{ gap: "12px" }}>
            <button
              id="english"
              style={{
                backgroundColor: isEnglish ? "white" : "#0092ad",
                color: isEnglish ? "black" : "white",
                border: "2px solid #0092ad",
                padding: "5px 15px",
                cursor: "pointer",
                borderRadius: "5px",
              }}
              onClick={() => handleLanguageSwitch("english")}
            >
              English
            </button>
            <button
              id="hindi"
              style={{
                backgroundColor: isEnglish ? "#0092ad" : "white",
                color: isEnglish ? "white" : "black",
                border: "2px solid #0092ad",
                padding: "5px 15px",
                cursor: "pointer",
                borderRadius: "5px",
              }}
              onClick={() => handleLanguageSwitch("hindi")}
            >
              Hindi
            </button>
          </div>

          <div className="pl-4 pr-4 pt-2">
            {isEnglish
              ? englishText.map((line, index) => (
                  <h4
                    key={index}
                    align="left"
                    id="modalbodylines"
                    style={{ lineHeight: "1.5", fontSize: "15px" }}
                  >
                    {line}
                  </h4>
                ))
              : hindiText.map((line, index) => (
                  <h4
                    key={index}
                    align="left"
                    id="modalbodylines"
                    style={{ lineHeight: "1.5", fontSize: "15px" }}
                  >
                    {line}
                  </h4>
                ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
