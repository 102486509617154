import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function NotFound404() {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
  }, []);

  return (
    <div className="w-100 h-100 d-flex flex-column text-center align-items-center pt-5">
      <div className="MuiTypography-root MuiTypography-h1 txtcolor fw-bold mb-4">
       JMD20
      </div>
      <div className="fs-2 text-red mb-3 fw-bolder">
        <span className="fs-3">❌</span>404 Not found
        <span className="fs-3">❌</span>
      </div>
      <div className="fs-3">
        It looks like you got on the wrong path. Get back now. 👇
      </div>
      <button
        className="btn btn-primary w-25"
        onClick={() => {
          navigate("/");
        }}
      >
        Home
      </button>
    </div>
  );
}
